import './assets/css/layout.css'
import './assets/css/button.css'
import './assets/css/box.css'
import './assets/css/question.css'
import './assets/css/accordion.css'
import './assets/css/illustration.css'
import './assets/css/tooltip.css'
import 'dayjs/locale/de.js'

import { ControlledAccordion, useAccordionProvider } from '@szhsin/react-accordion'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat.js'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FeedbackFormAccordion } from './components/FeedbackFormAccordion.tsx'
import { Footer } from './components/Footer.tsx'
import GlobalContext from './components/GlobalContext.tsx'
import { Header } from './components/Header.tsx'
import { Hints } from './components/Hints.tsx'
import { Messages } from './components/Messages.tsx'
import { QuickFeedback } from './components/QuickFeedback.tsx'
import { Result } from './components/Result.tsx'
import { SearchForm } from './components/SearchForm.tsx'
import { SpeechButton } from './components/SpeechButton.tsx'
import { VisionButton } from './components/VisionButton.tsx'
import { DEFAULT_SHORT_NAME, replaceLinks, UsedLinks } from './helpers.ts'
import { TypingMessageQueue } from './TypingMessageQueue.ts'
import { EventSourceMessage, useEventSource } from './useEventSource.ts'

dayjs.extend(localizedFormat)

const EVENT_SOURCE_NAME = 'default'
function App() {
  const [userId, setUserId] = useState<string | null>(localStorage.getItem('uID') || sessionStorage.getItem('uID'))
  const [cID, setCId] = useState<string | null>()
  const queryParams = new URLSearchParams(window.location.search)
  const [initialQuery] = useState<string | null>(queryParams.get('q'))
  const queryFromLocation = queryParams.get('q')
  const DO_SPEECH_BUTTON = !!queryParams.get('speech')
  const DO_VISION_BUTTON = !!queryParams.get('vision')
  const { mainLanguage, org } = useContext(GlobalContext)
  const [sseUri, setSseUri] = useState('')
  const [lastUpdate, setLastUpdate] = useState<string>(`${import.meta.env.VITE_DATE_DE}`)

  const { close: eventSourceClose, start: eventSourceStart } = useEventSource()

  const { host } = useContext(GlobalContext)
  const getQueryFromLocation = useCallback(() => {
    // Use the value of the query parameter as needed
    if (queryFromLocation) {
      setInputValue(queryFromLocation)
    }
  }, [queryFromLocation])

  useEffect(() => {
    fetch(host + `/${org}/lastUpdate`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(async response => {
      const lastUpdate = await response.text()
      const date = dayjs(lastUpdate)
      if (date.isValid()) {
        setLastUpdate(date.locale('de').format('LL'))
      }
    })
    fetch(host + `/${org}/log`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ referrer: document.referrer || null, initialQuery: initialQuery, uID: userId }),
    })
      .then(async response => {
        const user = await response.json()
        if (user && user.id) {
          localStorage.setItem('uID', user.id)
          setUserId(user.id)
          setCId(user.cID)
        }
      })
      .catch(error => {
        console.log('Error Log', error)
      })
  }, [initialQuery, host, org, userId])

  useEffect(() => {
    getQueryFromLocation()
  }, [getQueryFromLocation])
  const { t } = useTranslation()

  useEffect(() => {
    const handleLocationChange = () => {
      // Handle location change here
      getQueryFromLocation()
      setQueryResult(t('results.initial').replace('{VITE_DATE_DE}', lastUpdate))
    }

    // Add event listener for location change
    window.addEventListener('popstate', handleLocationChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleLocationChange)
    }
  }, [t, getQueryFromLocation, lastUpdate])

  const [inputValue, setInputValue] = useState('')
  const [queryValue, setQueryValue] = useState('')
  const [requestId, setRequestId] = useState<string | null>(null)
  const [disabledTimeout, setDisabledTimeout] = useState<number | undefined>(undefined)
  const [erzFunction, setErzFunction] = useState<{ query: string; function: string } | null>(null)

  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [queryResult, setQueryResult] = useState<string>('')
  const [linksResult, setLinksResult] = useState<string>('')
  const [usedLinksResult, setUsedLinksResult] = useState<UsedLinks[]>([])

  const [otherModel, setOtherModel] = useState<boolean>(false)
  const [maybeBadRating, setMaybeBadRating] = useState<boolean>(false)

  const feedbackRef = useRef(null)
  const hintRef = useRef(null)
  const questionRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const setNewEventSource = useCallback(
    (queryValue: string, model: string, ft?: string) => {
      if (queryValue && org) {
        // the random part is just that we create a new event source when we submit...
        // maybe there are better solutions for this.
        const source = `${host}/${org}/assistant/sse?query=${encodeURIComponent(
          queryValue,
        )}&key=X9hL4Gp5W2D7eRtF&r=${Math.floor((new Date().getTime() / 1000) % 600)}${userId ? `&uID=${userId}` : ''}${
          cID ? `&cID=${cID}` : ''
        }&lang=${mainLanguage}${model !== DEFAULT_SHORT_NAME.MODEL_3_5_4k ? `&modelId=${model}` : ''}${
          ft ? `&ft=${ft}` : ''
        }`

        setSseUri(source)
      }
    },
    [cID, host, org, mainLanguage, userId],
  )

  const sendSubmit = (inputValue: string, model = DEFAULT_SHORT_NAME.MODEL_3_5_4k) => {
    const fetchData = async () => {
      setIsLoading(true)
      setErzFunction(null)
      setOtherModel(false)
      setUsedLinksResult([])
      setNewEventSource(inputValue, model)
      setLinksResult('')
      setMaybeBadRating(false)
      setQueryResult(t('answers.oneMoment'))
    }
    if (inputValue.trim() === '') return
    setSubmitDisabled(true)
    setQueryValue(inputValue)

    if (disabledTimeout) {
      clearTimeout(disabledTimeout)
    }
    setDisabledTimeout(
      window.setTimeout(() => {
        setSubmitDisabled(false)
      }, 5000),
    )
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('q', inputValue)
    if (queryFromLocation !== inputValue) {
      window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`)
    }
    document.title = `${import.meta.env.VITE_SITE_NAME}: ${inputValue}`
    fetchData()
  }

  const parseMessageFromEventSource = useCallback(
    (parsedData: Record<string, any>, queue: TypingMessageQueue) => {
      try {
        if (parsedData.links) {
          setLinksResult(linksResult => {
            return linksResult.concat(parsedData.links)
          })
        }
        if (parsedData.usedLinks) {
          setUsedLinksResult(linksResult => {
            return linksResult.concat(parsedData.usedLinks)
          })
        }
        if (parsedData.maybeBadRating) {
          setMaybeBadRating(true)
        }
        if (parsedData.response) {
          const answerEnd = () => {
            setSubmitDisabled(false)
            setIsLoading(false)
            setOtherModel(true)
          }
          if (parsedData.response === '__THIS_IS_THE_ANSWER_END__') {
            answerEnd()
            return
          }

          if (parsedData.response === '__THIS_IS_THE_END__') {
            eventSourceClose(EVENT_SOURCE_NAME)
            queue.enqueue(parsedData.response)
            queue.setThisIsTheEnd()
            setQueryValue('')
            answerEnd()
            return
          }
          if (parsedData.response === '__CLR__') {
            setSubmitDisabled(false)
            queue.enqueue(parsedData.response)
            setOtherModel(false)
            return
          }

          queue.enqueue(parsedData.response)
          /*setQueryResult(queryResult => {
            return queryResult.concat(parsedData.response)
          })*/
        }
        if (parsedData.erz) {
          setErzFunction({ query: queryValue, function: JSON.stringify(parsedData.erz) })
        }
        if (parsedData.timetable) {
          setErzFunction({ query: queryValue, function: JSON.stringify(parsedData.timetable) })
        }
        if (parsedData.id) {
          setRequestId(parsedData.id)
        }
        if (parsedData.model && parsedData.model.model === DEFAULT_SHORT_NAME.MODEL_3_5_16k) {
          setSubmitDisabled(false)
          setQueryResult(`Wir versuchen's mit längerem Kontext...`)
          if (parsedData.model.query) {
            setQueryValue(parsedData.model.query)
            setNewEventSource(parsedData.model.query, parsedData.model.model, parsedData.model.ft)
            setInputValue(parsedData.model.query)
          } else {
            setNewEventSource(queryValue, parsedData.model.model, parsedData.model.ft)
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    [eventSourceClose, queryValue, setNewEventSource],
  )

  useEffect(() => {
    let linksReceived = false
    try {
      if (!sseUri || !queryValue) {
        return
      }
      setIsLoading(true)
      const queue = new TypingMessageQueue(setQueryResult)
      const onerror = (e: Event) => {
        // for some strange reason, we get an error event before the server sends the __THIS_IS_THE_END__ event
        // in some cases. We don't need to show that error, after we received some links... The main result is here anyway
        // For example: Ask for "Wer ist Inka?" on WintiGPT....
        if (!linksReceived) {
          setQueryResult(queryResult =>
            queryResult.concat(`

${t('answers.serverConnectionError')}`),
          )
        }
        console.log('SSE Error', e)
        eventSourceClose(EVENT_SOURCE_NAME)
        setQueryValue('')
        setSubmitDisabled(false)
        setIsLoading(false)
        setOtherModel(false)
      }
      const onmessage = (event: EventSourceMessage) => {
        const parsedData = JSON.parse(event.data)

        if (parsedData.links) {
          linksReceived = true
        }
        parseMessageFromEventSource(parsedData, queue)
      }
      eventSourceStart(EVENT_SOURCE_NAME, sseUri, onmessage, onerror)

      return () => {
        eventSourceClose(EVENT_SOURCE_NAME)
      }
    } catch (error) {
      console.log(error)
    }
  }, [eventSourceClose, eventSourceStart, parseMessageFromEventSource, queryValue, sseUri, t])

  const changeModel = () => {
    sendSubmit(inputValue, DEFAULT_SHORT_NAME.MODEL_3_5_16k)
  }

  const providerValue = useAccordionProvider({
    allowMultiple: true,
    transition: true,
    transitionTimeout: 450,
  })

  return (
    <div className={`layout ${isLoading ? 'answer-is-loading' : ''}`}>
      <Header slogan={true} />
      <main className="main">
        <div className="container">
          <div className="question-wrapper" ref={questionRef}>
            <SearchForm
              setInputValue={setInputValue}
              inputValue={inputValue}
              sendSubmit={sendSubmit}
              submitDisabled={submitDisabled}
            />
            {DO_VISION_BUTTON && (
              <VisionButton setIsLoading={setIsLoading} setInputValue={setInputValue} sendSubmit={sendSubmit} />
            )}
            {DO_SPEECH_BUTTON && <SpeechButton setInputValue={setInputValue} />}
          </div>
          <Result
            queryResult={replaceLinks(queryResult, usedLinksResult) || (!isLoading ? t('results.initial') : '')}
            query={queryValue}
            erzFunction={erzFunction}
            linksResult={linksResult}
            setLinksResult={setLinksResult}
            userId={userId}
            changeModel={changeModel}
            maybeBadRating={maybeBadRating}
            isLoading={isLoading}
            usedLinksResult={usedLinksResult}
            otherModelButton={otherModel && !sseUri.includes(`modelId=${DEFAULT_SHORT_NAME.MODEL_3_5_16k}`)}
            quickFeedback={
              <QuickFeedback
                providerValue={providerValue}
                questionRef={questionRef}
                feedbackRef={feedbackRef}
                hintRef={hintRef}
                requestId={requestId}
                userId={userId}
                inputValue={inputValue}
              />
            }
          />
          <Messages userId={userId} />
          <ControlledAccordion providerValue={providerValue}>
            <Hints hintRef={hintRef} lastUpdate={lastUpdate} />
            <FeedbackFormAccordion
              feedbackRef={feedbackRef}
              inputValue={inputValue}
              requestId={requestId}
              userId={userId}
            />
          </ControlledAccordion>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default App
